<template>
  <div class="auth-wrapper auth-v1 px-2 with-background">
    <div class="auth-inner py-2">
      <b-link class="brand-logo">
            <div>
              <img src="@/assets/images/logo/white-logo.png" alt="login-page-logo" style="width: 385px;"/>
            </div>
        </b-link>
        <!-- <div v-if="!isOnline">
        <div class="drop-container" :class="{ 'dropped': !isOnline }" >
        <b-alert show variant="danger" class="text-center text-bold"><span style="color: #f44336; text-transform: capitalize; ">You are currently offline. Please check your internet connection.</span></b-alert>
  </div>
       </div> -->
         <h2 class="mb-1 mt-4 text-center text-white text-uppercase">
          Login
        </h2>
      <!-- Login v1 -->
      <b-card class="mb-0">
        <div>
    <b-tabs content-class="mt-3" fill>
      <b-tab title="Team" active @click="loginTypeCheck('team')">
    <div>
      <p >    
        <div class="auth-footer-btn d-flex justify-content-center py-0 px-2">
          <b-button
            @click="googleLogin"
            size="lg"
            block
            href="javascript:void(0)"
            variant="outline-secondary"
          >
          <img src="@/assets/images/logo/google.png" alt="google-logo-login" style="width: 25px; margin-right: 5px;"/> <span class="text-xs" style="color: #7D7D7D;">Login with Google</span>
          </b-button>
        </div>
        <div class="divider my-2">
          <div class="divider-text">OR</div>
        </div>
        <!-- form -->
        <validation-observer ref="loginValidation" #default="{ invalid }" >
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group  class="py-0 px-2">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
              <b-form-input
                  id="team-email"
                  class="py-2 full-width-input"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group class="py-0 px-2">
              <!-- <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password-v1' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div> -->
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="team-password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge py-2"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
           <div class="py-0 px-2">
            <b-button 
              class="py-1"
              @click="validateForm"
              variant="danger"
              type="submit"
              block
              :disabled="invalid"
            >
             Log in
            </b-button>
           </div>
            <div style="display: flex; margin-top: 5px; justify-content: space-between;" class="py-0 px-2 text-xs">
              <p>Don't have an? <b-link style="color: #EF4138;">
                Account
              </b-link></p>
              <p>Forgot password?<b-link  style="color: #EF4138;" @click="ForgotPassordRediect">
                Reset
              </b-link></p>
            </div>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{ name: 'auth-register-v1' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text> -->

        <!-- social button -->
      </p>
    </div>
    </b-tab>
    <b-tab title="Client" @click="loginTypeCheck('client')">
      <div>
      <p >    
        <div class="auth-footer-btn d-flex justify-content-center py-0 px-2">
          <b-button
            @click="googleLogin"
            size="lg"
            block
            href="javascript:void(0)"
            variant="outline-secondary"
          >
          <img src="@/assets/images/logo/google.png" alt="google-loog-login" style="width: 25px; margin-right: 5px;"/> <span class="text-xs" style="color: #7D7D7D;">Login with Google</span>
          </b-button>
        </div>
        <div class="divider my-2">
          <div class="divider-text">OR</div>
        </div>
        <!-- form -->
        <validation-observer ref="loginValidation" #default="{ invalid }" >
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group  class="py-0 px-2">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="client-email"
                  class="py-2"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group class="py-0 px-2">
              <!-- <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password-v1' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div> -->
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="client-password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge py-2"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
           <div class="py-0 px-2">
            <b-button 
              class="py-1"
              @click="validateForm"
              variant="danger"
              type="submit"
              block
              :disabled="invalid"
            >
             Log in
            </b-button>
           </div>
            <div style="display: flex; margin-top: 5px; justify-content: space-between;" class="py-0 px-2 text-xs">
              <p>Don't have an? <b-link style="color: #EF4138;">
                Account
              </b-link></p>
              <p>Forgot password?<b-link  style="color: #EF4138;" @click="ForgotPassordRediect">
                Reset
              </b-link></p>
            </div>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{ name: 'auth-register-v1' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text> -->

        <!-- social button -->
      </p>
    </div>
    </b-tab>
  </b-tabs>
</div>
      </b-card>
      <h2 class="mt-1 text-center text-white tag-line" >
          Increase Revenue and Profitability Actionable Insights for your Digital Campaigns
        </h2>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BTabs,
  BTab,
  BAlert
} from "bootstrap-vue";
// import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
// import Cookies from "js-cookie";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BTabs,
    BTab,
    BAlert
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      required,
      email,
      loggingIn: false,
      loginType:"",
      isOnline: true, 
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    ForgotPassordRediect(){
      this.$router.push("/forgot-password");
    },
    handleOnline() {
      this.isOnline = true;
    },
    handleOffline() {
      this.isOnline = false;
    },
    loginTypeCheck(value){
      this.loginType = value
      this.userEmail = "",
      this.password = ""
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    validateForm() {
      this.loggingIn = true;
      if (this.loginType === '') {
      this.loginType = "team"
    }
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              loginType: this.loginType
            })
            .then((response) => {
              this.loggingIn = false;
              this.loginType = "";
              useJwt.setToken(response.data.token);
              useJwt.setUserName(response.data.admin.firstname);
              useJwt.setUserEmail(response.data.admin.email);
              //-------------
              useJwt.setAdminClientEnabled(response.data.admin.configuration.clients);
              useJwt.setAdminGMBEnabled(response.data.admin.configuration.gmb.enabled);
              useJwt.setAdminGMBDealersEnabled(response.data.admin.configuration.gmb_for_dealers);
              useJwt.setAdminAccessControlEnabled(response.data.admin.configuration.access_control);
              useJwt.setAdminTriggersControlEnabled(response.data.admin.configuration.triggers);

              useJwt.setAdminGMBGeoEnabled(response.data.admin.configuration.gmb.items.geo)
              useJwt.setAdminGMBSummaryEnabled(response.data.admin.configuration.gmb.items.summary)
              useJwt.setAdminGMBOrganicEnabled(response.data.admin.configuration.gmb.items.organic)
              useJwt.setAdminGMBPostEnabled(response.data.admin.configuration.gmb.items.posts)
              useJwt.setAdminGMBPaidEnabled(response.data.admin.configuration.gmb.items.paid)
              useJwt.setAdminGMBReviewEnabled(response.data.admin.configuration.gmb.items.reviews)
              useJwt.setAdminGMBStoreEnabled(response.data.admin.configuration.gmb.items.stores)
              
              // useJwt
              // window.location.reload(true);
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push("/dashboard");
              }
            })
            .catch((error) => {
              this.loggingIn = false;
              if (error.response.data && error.response.data.error) {
                this.showToast("Error", error.response.data.error, "danger");
                return;
              }

              if (error.response.data && error.response.data.errors) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  const error = error.response.data.errors[i];
                  this.showToast(
                    "Error",
                    `${error.msg - error.param}`,
                    "danger"
                  );
                }
                return;
              }

              this.showToast(
                "Unauthorized",
                "Incorrect Email / Password. Please try again.",
                "danger"
              );
            });
        } else {
          this.loggingIn = false;
        }
      });
    },
    googleLogin() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/admin/login/google/init`)
        .then((response) => {
          const url = response.data;
          window.location.replace(url);
        })
        .catch((error) => {
          this.showToast(
            "Error",
            "Couldn't process the request at the moment. Please try again later!",
            "danger"
          );
        });
    },
  },
  mounted(){
    this.isOnline = window.navigator.onLine;
// Add event listeners to detect online/offline changes
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  },
  beforeDestroy() {
    // Remove event listeners when the component is destroyed
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.with-background {
  background-image: url('../assets/images/logo/backgroundImage.svg');
  background-size: cover;

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #555555 !important;
    border-color: #dae1e7 #dae1e7 transparent;
    color: white !important;
    text-transform: capitalize;
    padding: 1rem 0 !important;
}
.nav-tabs .nav-link:after {
    left: 0;
    background:  #555555 !important;
}
.nav-tabs .nav-link {
    padding: 1rem 0 !important;
    border-radius: 0;
    color: white !important;
    background-color: #717171 !important;
}
.card-body {
    padding: 0rem !important;
}
.btn-outline-secondary {
    border: 1px solid #d8d6de !important;
    background-color: transparent;
}
}
.brand-logo {
  align-items: center;
  h2 {
    margin: 0;
  }
}

.tag-line{
  width: 420px; margin-left: auto; margin-right: auto;
}

.full-width-input {
    width: 100% !important;
}
 
@media only screen and (max-width: 767px) {
  .tag-line{
  width: 340px; margin-left: auto; margin-right: auto;font-size: 20px;
}
  .brand-logo {
  align-items: center;
  img{
    max-height: 60px !important;
    max-width: 270px !important;
  }
  h2 {
    margin: 0;
  }
}
}
.drop-container {
  transition: transform 0.8s ease;
}
.dropped {
  transform: translateY(10px);
}
</style>


